<template>
  <div class="content flex-column">
    <div class="flex-space-between">
      <div class="operation flex-center-v">
        <el-select
            v-model="searchForm.type"
            placeholder="筛查类型"
            @change="changeType"
            size="small"
            style="width:140px;margin-right:15px;">
          <el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-input v-model="searchForm.coding" placeholder="请输入机械编号" size="small" style="width:140px;margin-right:15px;" clearable></el-input>
        <el-select
            v-model="searchForm.year"
            placeholder="年份"
            size="small"
            filterable
            style="width:140px;margin-right:15px;">
          <el-option v-for="item in yearOptions" :label="item+'年'" :value="Number(item)" :key="item"></el-option>
        </el-select>
        <el-select
            v-if="searchForm.type=='month'"
            v-model="searchForm.month"
            placeholder="月份"
            size="small"
            style="width:140px;margin-right:15px;">
          <el-option v-for="item in monthOptions" :label="item+'月'" :value="Number(item)" :key="item"></el-option>
        </el-select>
      </div>
      <div class="search">
        <el-button type="primary" size="small" style="width:80px;" @click="exportTaiBan">导出</el-button>
        <el-button type="primary" size="small" style="width:80px;" @click="searchTaiBan">查询</el-button>
      </div>
    </div>
    <div class="cont flex-1-auto">
      <el-table v-if="searchForm.type=='month'" border :data="TaiBantableData" height="100%" :header-cell-style="{background:'#f5f5f5',color:'#333333'}">
        <el-table-column align="center"  prop="name" label="日期" width="200">
          <el-table-column align="center" prop="name" label="设备名称" width="200">
            <template slot-scope="scope">
              <div class="diaolanName" :title="scope.row.name">{{scope.row.name}}</div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column v-for="(item, index) in tableHeaderArray" :key="index" :label="item" width="55px" :prop="tableHeaderArray[index]">
          <template slot-scope="scope">
            <div>{{ scope.row.countList[index] == '0' ? '' : scope.row.countList[index] }}</div>
          </template>
        </el-table-column>
      </el-table>
      <el-table v-else :data="TaiBantableData" height="100%" :header-cell-style="{background:'#f5f5f5',color:'#333333'}">
        <el-table-column align="center"  prop="name" label="月份" width="200">
          <el-table-column align="center" prop="name" label="设备名称" width="200">
            <template slot-scope="scope">
              <div class="diaolanName" :title="scope.row.name">{{scope.row.name}}</div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column v-for="(item, index) in tableHeaderArrays" :key="index" :label="item" :prop="tableHeaderArrays[index]">
          <template slot-scope="scope">
            <div>{{ scope.row.countList[index] == '0' ? '' : scope.row.countList[index] }}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {downloadFile, getDate} from "@/utils/utils";
import BaseURL from "@/apis/baseurl";
import apis from "@/views/menus/api/security-check";

export default {
  name: "Five",

  components: {},

  props: {},

  data() {
    return {
      TaiBantableData:[],
      tableHeaderArrays:['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月','年累计','开工累计',],
      yearOptions:[],
      monthOptions:['1','2','3','4','5','6','7','8','9','10','11','12'],
      tableHeaderArray: [],
      searchForm:{
        // type:'year',
        type:'month',
        machineType: 0,
        year:'',
        month:''
      },
      typeOptions: [
        { value: 'month', label: '按月统计'},
        { value: 'year', label: '按年统计'},
      ]
    }
  },

  computed: {
    ...mapGetters(['companyId', 'optionItem']),
  },

  watch: {
    companyId() {
      this.initTaiBan()
    }
  },

  mounted() {
    this.yearOptions = this.getYear()
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    this.searchForm.year =getDate(new Date()).year
    this.searchForm.month =currentDate.getMonth() + 1
    const array= this.getEveryDay(currentMonth)
    this.tableHeaderArray = [...array,'月累计', '开工累计'];
    this.initTaiBan()
  },

  methods: {
    getYear(){
      const array = []
      for (let i=0;i<60;i++){
        if (i<10){
          array.push('200'+i)
        }else{
          array.push('20'+i)
        }
      }
      return array
    },
    // 改变年/月统计方式的时候
    changeType(){
      //如果为true则为台班，其次为台账
      this.searchTaiBan()
    },
    searchTaiBan(){
      if (this.searchForm.type=='month'){
        this.handleMonthChange('',this.searchForm.month)
      }else{
        this.handleMonthChange()
      }
      this.initTaiBan()
    },
    exportTaiBan(){
      const params = {
        departmentId: this.optionItem.departmentId,
        departmentType: this.optionItem.departmentType,
        coding: this.searchForm.coding||''
      }
      if (this.searchForm.type == 'month'){
        params.year = this.searchForm.year
        params.month = this.searchForm.month
        const url = BaseURL + `/admin/public/machineExportByMonth?machineType=0&departmentType=${params.departmentType}&departmentId=${params.departmentId}&month=${params.month}&year=${params.year}&coding=${params.coding}`
        downloadFile(url, {}, '台班报表.xls')
      }else{
        params.year = this.searchForm.year
        const url = BaseURL + `/admin/public/machineExportByYear?machineType=0&departmentType=${params.departmentType}&departmentId=${params.departmentId}&year=${params.year}&coding=${params.coding}`
        downloadFile(url, {}, '台班报表.xls')
      }
    },
    getCountDays (m,mon){
      //如果指定月份
      if (mon){
        let year = this.searchForm.year
        let month =this.searchForm.month
        let date = new Date(year, month, 0); // 这里的month，比我们日常说的几月份小1，10则表示11月份
        return date.getDate();
        //  未指定则显示当月月份
      }else{
        const currentDate = new Date();
        currentDate.setMonth(m + 1);
        currentDate.setDate(0);
        return currentDate.getDate();
      }
    },
    getEveryDay (m,mon){
      const dayArray = [];
      // const month = m +1;
      if (mon){
        const day = this.getCountDays(m,mon);
        for (let i = 1; i <= day; i++){
          dayArray.push(i + "日");
        }
        return dayArray;
      }else{
        const day = this.getCountDays(m);
        for (let i = 1; i <= day; i++){
          dayArray.push(i + "日");
        }
        return dayArray;
      }
    },
    handleMonthChange (val,mon){
      const array = this.getEveryDay('',mon)
      this.tableHeaderArray =[...array,'月累计', '开工累计'] ;
    },
    initTaiBan(){
      if (!this.companyId) return this.initTaiBan()
      const params = {
        departmentId:this.optionItem.departmentId,
        departmentType:this.optionItem.departmentType,
        coding:this.searchForm.coding,
        year:this.searchForm.year,
        machineType:0
      }
      if (this.searchForm.type=='month'){
        params.month = this.searchForm.month
        apis.largeMachineRecordByMonth(params).then(res=>{
          this.TaiBantableData = res
        })
      }else{
        apis.largeMachineRecordByYear(params).then(res=>{
          this.TaiBantableData = res
        })
      }
    },
  }
}
</script>

<style scoped>
/*::v-deep 这里主要的作用就是用来强制修改element默认的样式*/
::v-deep .el-table thead.is-group th {
  background: none;
  padding: 0px;
}

::v-deep .el-table thead.is-group tr:first-of-type th:first-of-type {
  border-bottom: none; /*中间的横线去掉*/
}

::v-deep .el-table thead.is-group tr:first-of-type th:first-of-type div.cell {
  text-align: right; /*上边文字靠右*/
}

::v-deep .el-table thead.is-group tr:last-of-type th:first-of-type div.cell {
  text-align: left; /*下边文字靠左*/
}
/*核心代码*/
::v-deep .el-table thead.is-group tr:first-of-type th:first-of-type:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 150px; /*斜线的长度*/
  top: 0;
  left: 0;
  background-color: grey;
  opacity: 0.2;
  display: block;
  transform: rotate(-43deg); /*调整斜线的角度*/
  transform: rotate(-68deg); /*调整斜线的角度*/
  -webkit-transform-origin: top;
  transform-origin: top;
}

::v-deep .el-table thead.is-group tr:last-of-type th:first-of-type:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 150px; /*斜线的长度*/
  bottom: 0;
  right: 0;
  background-color: grey;
  opacity: 0.2;
  display: block;
  transform: rotate(-45deg); /*调整斜线的角度*/
  transform: rotate(-69deg); /*调整斜线的角度*/
  -webkit-transform-origin: bottom;
  transform-origin: bottom;
}
/*::v-deep .el-table thead.is-group th {*/
/*  height: 27.4px;*/
/*}*/
</style>
